export enum RoutesEnums {
  main = "main",
  ui_kit = "ui-kit",
  maps = "maps",
  api = "api",
  registration = "registration",
  authorization = "authorization",
  verification = "verification",
  personal = "personal",
  personal_payments = "personal-payments",
  personal_settings = "personal-settings",
  personal_notifications = "personal-notifications",
  personal_blocked_faq = "personal-blocked-faq",
  reset_password = "reset-password",
  forgot = "forgot",
  events = "events",
  events_detail = "events_detail",
  become_pro = "become_pro",
  become_manager = "become_manager",
  personal_information = 'personal_information',
  questionnaires_organization = 'questionnaires_organization',
  questionnaires_manager = 'questionnaires_manager',
  questionnaires_pro = 'questionnaires_pro',
  not_found = "not-found",
}
