import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from "pinia";
import VMask from "@ssibrahimbas/v-mask";

createApp(App)
  .use(VMask)
  .use(router)
  .use(createPinia())
  .mount('#app')
