<template>
  <router-view />
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import {useDomStore} from "@/store/dom";

const domStore = useDomStore();

onMounted(() => {
  window.addEventListener('resize', () => {
    domStore.windowSize.width = window.innerWidth;
    domStore.windowSize.height = window.innerHeight;
  })
});
</script>

<style>
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import "@/assets/css/normalize.css";
@import "@/assets/fonts/fonts.css";
@import "vue-multiselect/dist/vue-multiselect.esm.css";

* {
  box-sizing: inherit;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
}

html,
body {
  width: 100%;
  min-height: 100vh;
}

body {
  box-sizing: border-box;
  font-family: 'Wix Madefor Display', sans-serif;
  display: flex;
  flex-direction: column;
}

#app {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

:root {
  --color_ultra-violet: #30546A;
  --color_bittersweet: #F9564F;
  --color_light-bittersweet: #F3CAC4;
  --color_dark-bittersweet: #BF443F;
  --color_sunglow: #FECA50;
  --color_honey: #FFE7AE;
  --color_dartmouth-green: #0A6847;
  --color_french-gray: #ACB9CB;
  --color_ghost-white: #EDF0F3;
  --color_space-cadet: #2D343F;
  --color_space-cadet-opacity: #2D343F80;
  --color_success: #2F9672;
  --color_error: #D15A48;
  --color_eerie-black: #222222;
  --color_white: #fff;
  --color_white-opacity: rgba(255, 255, 255, .8);
  --color_dark-gray: #A5A5A5;
  --color_dark-opacity: #30546A80;
  --color_shadow_1: #2221351A;
  --color_shadow_2: #2129351A;
  --color_light-gray: #F1F1F6;
  --color_ghost-gray: #F3F6F9;
  --color_transparent: transparent;
  --indent_xs: 10px;
  --indent_sm: 14px;
  --indent_md: 20px;
  --indent_lg: 24px;
  --indent_xl: 40px;
  /* FONTS */
  /* SIZE */
  --f-size_6xl: 96px;
  --f-size_5xl: 72px;
  --f-size_4xl: 48px;
  --f-size_3xl: 32px;
  --f-size_2xl: 24px;
  --f-size_xl: 20px;
  --f-size_l: 18px;
  --f-size_m: 16px;
  --f-size_s: 14px;
  --f-size_xs: 12px;
  --f-size_2xs: 10px;
  /* LINE-HEIGHT */
  --line-height-l: 160%;
  --line-height-m: 150%;
  --line-height-s: 140%;
  --line-height-xs: 120%;
  --line-height-2xs: 100%;
  /* LETTER-SPACING */
  --spacing_l: 0.16em;
  --spacing_m: 0.08em;
  --spacing_s: 0.04em;
  --spacing_xs: 0.02em;
  /* FONT-WEIGHT */
  --f-weight_bold: 700;
  --f-weight_semi-bold: 600;
  --f-weight_semi-regular: 400;
}

/* MARGIN */

/* XS */

._margin_top-xs {
  margin-top: var(--indent_xs);
}

._margin_right-xs {
  margin-right: var(--indent_xs);
}

._margin_bottom-xs {
  margin-bottom: var(--indent_xs);
}

._margin_left-xs {
  margin-left: var(--indent_xs);
}

/* SM */

._margin_top-sm {
  margin-top: var(--indent_sm);
}

._margin_right-sm {
  margin-right: var(--indent_sm);
}

._margin_bottom-sm {
  margin-bottom: var(--indent_sm);
}

._margin_left-sm {
  margin-left: var(--indent_sm);
}

/* MD */

._margin_top-md {
  margin-top: var(--indent_md);
}

._margin_right-md {
  margin-right: var(--indent_md);
}

._margin_bottom-md {
  margin-bottom: var(--indent_md);
}

._margin_left-md {
  margin-left: var(--indent_md);
}

/* LG */

._margin_top-lg {
  margin-top: var(--indent_lg);
}

._margin_right-lg {
  margin-right: var(--indent_lg);
}

._margin_bottom-lg {
  margin-bottom: var(--indent_lg);
}

._margin_left-lg {
  margin-left: var(--indent_lg);
}

/* XL */

._margin_top-xl {
  margin-top: var(--indent_xl);
}

._margin_right-xl {
  margin-right: var(--indent_xl);
}

._margin_bottom-xl {
  margin-bottom: var(--indent_xl);
}

._margin_left-xl {
  margin-left: var(--indent_xl);
}

/* LINK */

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1276px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
}

.container_min {
  max-width: 1144px;
}

.centering-block {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.section-offset {
  margin-bottom: 60px;
}

.section-offset_padding {
  padding: 60px 0;
}

.section-offset_80 {
  margin-bottom: 80px;
}

.content-block {
  margin: 0 0 80px;
}

.content-block:last-child {
  margin-bottom: 0;
}

.content-block p {
  margin: 0 0 16px;
  font-size: var(--f-size_m);
  line-height: var(--line-height-xs);
}

.content-block p:last-child {
  margin-bottom: 0;
}

.content-block p b {
  font-weight: var(--f-weight_semi-bold);
  font-size: var(--f-size_l);
  line-height: var(--line-height-xs);
}

.content-block ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-block ol {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-block ol li {
  font-size: var(--f-size_m);
  line-height: var(--line-height-xs);
}

.content-block ul li {
  position: relative;
  padding-left: 24px;
  font-size: var(--f-size_m);
  line-height: var(--line-height-xs);
}

.content-block ul li::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 10px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--color_space-cadet);
}

.multiselect__tags {
  padding: 11.5px 40px 0 13px;
  min-height: 48px;
  border-radius: 8px;
  border: 1px solid var(--color_transparent);
  background-color: var(--color_ghost-white);
}

.multiselect__tag {
  white-space: initial;
}

.multiselect__select {
  height: 48px;
}

.multiselect__select::before {
  top: 58%;
}

.multiselect__placeholder {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--f-size_l);
  color: var(--color_french-gray);
}

.multiselect__input {
  background-color: var(--color_ghost-white);
}

.multiselect__input, .multiselect__single {
  padding: 0;
  font-size: var(--f-size_l);
  color: var(--color_eerie-black);
}

.slider-button {
  position: absolute;
  top: 0;
  z-index: 10;
  padding: 0;
  width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  border: initial;
  cursor: pointer;
  transition: opacity .2s ease;
}

.slider-button_prev {
  left: 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.slider-button_next {
  right: 0;
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  justify-content: flex-end;
}

.slider-button.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.slider-button__decor {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: url("@/assets/icons/slider-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 36px 36px;
}

.slider-button_prev .slider-button__decor {
  transform: rotate(180deg);
}

.slider-pagination {
  position: absolute;
  bottom: 24px!important;
  left: 50%!important;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.slider-pagination .swiper-pagination-bullet {
  margin: 0!important;
  opacity: 1;
  background-color: var(--color_white);
  width: 10px;
  height: 10px;
  transition: width .2s ease, background-color .2s ease, border-radius .2s ease;
}

.slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  border-radius: 10px;
  background-color: var(--color_bittersweet);
}

.multiselect__single {
  background-color: initial;
}

@media (max-width: 1024px) {
  .container {
    padding: 0 22px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 12px;
  }

  .section-offset_padding {
    padding: 40px 0;
  }
}
</style>
