import { useRouter } from "vue-router";
import { defineStore } from "pinia";
import { RoutesEnums } from "@/interfaces/RoutesEnums";
import request from "@/services/request";
import {isEmptyObj} from "@/functions/helpers";
import {UserInterface} from "@/interfaces/UserInterface";
import {ref} from "vue";

export const useUserStore = defineStore("user", () => {
  const router = useRouter();
  const user = ref<UserInterface>({});

  async function me() {
    const response = await request.get("/api/auth/me");
    if (response.status === 200) {
      user.value.email = response.data.email;
      user.value.email_verified_at = response.data.email_verified_at;
      user.value.name = response.data.name;
      user.value.profile_photo = response.data.profile_photo;
      user.value.roles = response.data.roles;
      user.value.birth = response.data.birth?.split(' ')[0];
      user.value.phone = response.data.phone;
      user.value.address = response.data.address;
      user.value.settings = response.data.settings
        ? {
          type: response.data.settings.type,
          organization_type: response.data.settings.organization_type,
          organization_name: response.data.settings.organization_name,
          organization_address: response.data.settings.organization_address,
          status: response.data.settings.status,
          decline_reason: response.data.settings.decline_reason,
          passport_birthday: response.data.settings.passport_birthday,
          passport_name: response.data.settings.passport_name,
          passport_number: response.data.settings.passport_number,
          passport_photo: response.data.settings.passport_photo,
          organization_socials: response.data.settings.organization_socials,
          blocked_reason: response.data.settings.blocked_reason,
          deleted_at: response.data.settings.deleted_at,
          blocked_at: response.data.settings.blocked_at ?
            new Date(response.data.settings.blocked_at).toLocaleDateString('en-EN', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
          }) : null,
          restore_at: response.data.settings.blocked_at ? getRestoreAt(response.data.settings.blocked_at) : null
        }
        : undefined;

      user.value.blocked = undefined;
      user.value.deleted = undefined;
    } else {
      user.value = {};
    }
  }

  function getRestoreAt(blocked_at: string) {
    const blockedDate = new Date(blocked_at)
    return `${blockedDate.getMonth() + 1}/${blockedDate.getDate()}/${blockedDate.getFullYear() + 1}`
  }

  function isAuthenticated() {
    if (isEmptyObj(user.value)) {
      localStorage.removeItem("token");
      return false;
    }

    return true;
  }

  function isVerification() {
    if (!isEmptyObj(user.value) && user.value.email_verified_at !== null) {
      return true;
    }

    return false;
  }

  async function register(data: any) {
    const response = await request.post("/api/auth/register", data);
    if (response.status === 200) {
      login({ email: data.email, password: data.password });
    }

    return response;
  }

  async function login(data: any) {
    const response = await request.post("/api/auth/login", data);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.data.token.access_token);
      router.push({ name: RoutesEnums.personal });
    }

    return response;
  }

  async function verify(data: any) {
    const response = await request.get(`/api/auth/verify/email/${data.code}`);
    if (response.status === 200) {
      console.log(response);
      router.push({ name: RoutesEnums.personal });
    }

    return response;
  }

  async function forgot(data: any) {
    const response = await request.post("/api/auth/password/forgot", data);
    if (response.status === 200) {
      console.log(response);
    }

    return response;
  }

  async function resetPassword(data: any) {
    const response = await request.post("/api/auth/password/reset", data);
    if (response.status === 200) {
      console.log(response);
      router.push({ name: RoutesEnums.authorization });
    }

    return response;
  }

  async function sendCode(data: any) {
    const response = await request.post("/api/auth/verify/email-code", data);
    if (response.data.status == 200) {
      console.log(response);
    }

    return response;
  }

  async function edit(data: any) {
    return await request.patch("/api/profile/update", data);
  }

  async function editOrganization(data: any) {
    return await request.patch("/api/profile/settings/organization", data);
  }

  async function createSetting(data: any) {
    return await request.post("/api/profile/settings", data);
  }

  async function createDetails(data: any) {
    return await request.post("/api/profile/details", data);
  }

  async function createDataProfile(data: any) {
    return await request.post("/api/profile/data", data);
  }

  function logout() {
    localStorage.removeItem('token');
    router.push({name: RoutesEnums.main})
  }

  return {
    user,
    me,
    isAuthenticated,
    isVerification,
    sendCode,
    register,
    login,
    verify,
    forgot,
    resetPassword,
    edit,
    editOrganization,
    createSetting,
    createDetails,
    createDataProfile,
    logout
  };
});
