export enum PageEnums {
  main = 'Home page',
  ui_kit = 'UI kit',
  maps = 'Maps',
  api = 'API',
  personal_information = 'Personal information',
  registration = 'Registration',
  authorization = 'Authorization',
  verification = 'Verification',
  personal = 'Personal',
  personal_payments = 'Personal payments',
  personal_settings = 'Personal settings',
  personal_notifications = 'Personal notifications',
  personal_deleted_faq = 'Personal deleted faq',
  reset_password = 'Reset password',
  forgot = 'Forgot',
  events = 'Events',
  events_detail = 'Events detail',
  become_pro = 'Become pro',
  become_manager = 'Become manager',
  questionnaires_organization = 'Add organization',
  questionnaires_manager = 'Add manager',
  questionnaires_pro = 'Add pro',
  not_found = 'Not found'
}
