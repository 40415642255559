interface IErrorData {
  error_status?: boolean
  error_code?: string
  error_detail?: string
}

function showErrorNotice(message: string) {
  alert(message)
}

function getErrorMessage(errorData?: IErrorData) {
  switch (errorData?.error_code) {
    case 'code':
      return ''
  }

  return null
}

function assertError(response?: Response, jsonData?: IErrorData) {
  if (response?.ok) {
    return
  }

  if ((jsonData?.error_detail?.length ?? 0) > 0) {
    showErrorNotice(jsonData?.error_detail ?? '')
  } else {
    const errorMessage = getErrorMessage(jsonData)
    if (errorMessage) {
      showErrorNotice(errorMessage)
    }
  }
}

function setHeadersDefault () {
  const token = localStorage.getItem("token");

  const headersDefault: any = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }

  if (token) {
    headersDefault["Authorization"] = `Bearer ${token}`
  }

  return headersDefault;
}

export default {
  async get(
    url: string,
    queryParamsObject: { [key: string]: string | number } = {},
    retryCount = 5,
    headers: { [key: string]: string } = {},
  ): Promise<{ data: any; status: number | undefined; }> {
    const queryParams = {
      ...queryParamsObject,
      ['q_fix']: `${Date.now()}`
    }

    const headersDefault = setHeadersDefault()

    const resultHeaders = {
      ...headersDefault,
      ...headers
    }

    const queryParamsStr = new URLSearchParams(queryParams)
    const res: any = await fetch(`${url}?${queryParamsStr}`, {
      headers: resultHeaders,
    }).catch(async () => {
      if (retryCount > 0) {
        if (res?.status !== 401) {
          return await this.get(url, queryParams, retryCount - 1)
        }
      }
    })

    const jsonData = await res?.json()
    assertError(res, jsonData)

    return { status: res?.status, data: jsonData }
  },

  async post(
    url: string,
    data: { [key: string]: string | number | null | object } = {},
    queryParams: { [key: string]: string } = {},
    headers: { [key: string]: string } = {},
  ) {
    let res: any = {};

    if (data instanceof FormData) {
      console.log('test');
      const token = localStorage.getItem("token");
      const headersResult: any = {}
      if (token) {
        headersResult["Authorization"] = `Bearer ${token}`
      }
      res = await fetch(
        `${url}?${new URLSearchParams(queryParams)}`,
        {
          method: 'POST',
          headers: headersResult,
          body: data
        }
      )
    } else {
      const headersDefault = setHeadersDefault()

      const resultHeaders = {
        ...headersDefault,
        ...headers
      }

      res = await fetch(
        `${url}?${new URLSearchParams(queryParams)}`,
        {
          method: 'POST',
          headers: resultHeaders,
          body: JSON.stringify(data)
        }
      )
    }

    const jsonData = await res.json()

    assertError(res, jsonData)

    return { status: res?.status, data: jsonData }
  },

  async patch(
    url: string,
    data: { [key: string]: string | number | null | object } = {},
    queryParams: { [key: string]: string } = {},
    headers: { [key: string]: string } = {},
  ) {
    const headersDefault = setHeadersDefault()

    const resultHeaders = {
      ...headersDefault,
      ...headers
    }

    const res = await fetch(
      `${url}?${new URLSearchParams(queryParams)}`,
      {
        method: 'PATCH',
        headers: resultHeaders,
        body: JSON.stringify(data)
      }
    )

    const jsonData = await res.json()

    assertError(res, jsonData)

    return { status: res?.status, data: jsonData }
  },

  async put(
    url: string,
    data: { [key: string]: string | number | null | object } = {},
    queryParams: { [key: string]: string } = {},
    headers: { [key: string]: string } = {},
  ) {
    const headersDefault = setHeadersDefault()

    const resultHeaders = {
      ...headersDefault,
      ...headers
    }

    const res = await fetch(
      `${url}?${new URLSearchParams(queryParams)}`,
      {
        method: 'PUT',
        headers: resultHeaders,
        body: JSON.stringify(data)
      }
    )

    const jsonData = await res.json()

    assertError(res, jsonData)

    return { status: res?.status, data: jsonData }
  },

  async sendFile(url: string, data: FormData, queryParams: { [key: string]: string } = {}) {
    const fullUrl = `${url}?${new URLSearchParams(queryParams)}`

    const initResponse = await fetch(fullUrl, {
      method: 'POST',
      body: data
    })

    if (!initResponse.ok) {
      throw new Error('Something went wrong')
    }

    return initResponse.json()
  },

  async sendFileJson() {
    return ''
  }
}
