export function isEmptyObj(obj: object) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export function addField(params: any) {
  const defaultParams = {
    value: "",
    type: "text",
    label: "",
    placeholder: "Enter text",
    errors: [],
    vision: true,
    description: "",
    required: false,
  }

  return {...defaultParams, ...params};
}
