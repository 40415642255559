import {defineStore} from "pinia";

export const useDomStore = defineStore('dom', () => {
  const headerHeight = 0;
  const windowSize = {
    width: 0,
    height: 0,
  }

  return {
    headerHeight,
    windowSize
  }
})
