import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {RoutesEnums} from "@/interfaces/RoutesEnums";
import {useUserStore} from "@/store/user";
import {PageEnums} from "@/interfaces/PageEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RoutesEnums.main,
    component: () => import("@/views/MainPage.vue"),
    meta: {
      page_name: PageEnums.main,
    }
  },
  {
    path: "/ui-kit",
    name: RoutesEnums.ui_kit,
    component: () => import("@/views/UIKit.vue"),
    meta: {
      page_name: PageEnums.ui_kit,
    }
  },
  {
    path: "/maps",
    name: RoutesEnums.maps,
    component: () => import("@/views/MapExample.vue"),
    meta: {
      page_name: PageEnums.maps,
    }
  },
  {
    path: "/api-docs",
    name: RoutesEnums.api,
    component: () => import("@/views/ApiDocs.vue"),
    meta: {
      page_name: PageEnums.api,
    }
  },
  {
    path: "/sign-up",
    name: RoutesEnums.registration,
    component: () => import("@/views/authentication/RegistrationView.vue"),
    meta: {
      noAuth: true,
      page_name: PageEnums.registration,
    },
  },
  {
    path: "/sign-in",
    name: RoutesEnums.authorization,
    component: () => import("@/views/authentication/AuthorizationView.vue"),
    meta: {
      noAuth: true,
      page_name: PageEnums.authorization,
    },
  },
  {
    path: "/verification",
    name: RoutesEnums.verification,
    component: () => import("@/views/authentication/VerificationView.vue"),
    meta: {
      needAuth: true,
      noVerification: true,
      page_name: PageEnums.verification,
    },
  },
  {
    path: "/personal",
    name: RoutesEnums.personal,
    component: () => import("@/views/PersonalAccount.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.personal,
      menuFooterRight: true,
    },
  },
  {
    path: '/personal/information',
    name: RoutesEnums.personal_information,
    component: () => import('@/views/PersonalInformation.vue'),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.personal_information,
    }
  },
  {
    path: "/personal/settings",
    name: RoutesEnums.personal_settings,
    component: () => import("@/views/PersonalSettings.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.personal_settings
    },
  },
  {
    path: "/personal/payments",
    name: RoutesEnums.personal_payments,
    component: () => import("@/views/PaymentsAndDisbursements.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.personal_payments
    },
  },
  {
    path: "/personal/notifications",
    name: RoutesEnums.personal_notifications,
    component: () => import("@/views/PersonalNotification.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.personal_notifications
    },
  },
  {
    path: "/personal/blocked-faq",
    name: RoutesEnums.personal_blocked_faq,
    component: () => import("@/views/BlockedAccountFAQ.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.personal_deleted_faq
    },
  },
  {
    path: "/reset-password",
    name: RoutesEnums.reset_password,
    component: () => import("@/views/authentication/ResetPassword.vue"),
    meta: {
      page_name: PageEnums.reset_password
    },
  },
  {
    path: "/forgot",
    name: RoutesEnums.forgot,
    component: () => import("@/views/authentication/ForgotView.vue"),
    meta: {
      page_name: PageEnums.forgot
    },
  },
  {
    path: "/events",
    name: RoutesEnums.events,
    component: () => import("@/views/EventsView.vue"),
    meta: {
      page_name: PageEnums.events
    },
  },
  {
    path: "/event/:id",
    name: RoutesEnums.events_detail,
    component: () => import("@/views/DetailEventViews.vue"),
    meta: {
      page_name: PageEnums.events_detail
    },
  },
  {
    path: "/become-pro",
    name: RoutesEnums.become_pro,
    component: () => import("@/views/BecomeProView.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.become_pro,
      menuFooterLeft: true,
    },
  },
  {
    path: "/become-manager",
    name: RoutesEnums.become_manager,
    component: () => import("@/views/BecomeManagerView.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.become_manager,
      menuFooterLeft: true,
    },
  },
  {
    path: "/questionnaires-organization",
    name: RoutesEnums.questionnaires_organization,
    component: () => import("@/views/questionnaires/OrganizationView.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.questionnaires_organization
    },
  },
  {
    path: "/questionnaires-manager",
    name: RoutesEnums.questionnaires_manager,
    component: () => import("@/views/questionnaires/ManagerView.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.questionnaires_manager
    },
  },
  {
    path: "/questionnaires-pro",
    name: RoutesEnums.questionnaires_pro,
    component: () => import("@/views/questionnaires/ProView.vue"),
    meta: {
      needAuth: true,
      needVerification: true,
      page_name: PageEnums.questionnaires_pro
    },
  },
  {
    path: "/:catchAll(.*)",
    name: RoutesEnums.not_found,
    component: () => import("@/views/NotFound.vue"),
    meta: {
      page_name: PageEnums.not_found
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);

  const userStore = useUserStore();

  try {
    await userStore.me();
  } catch (e) {
    console.log(e)
  }

  const isAuthenticated = userStore.isAuthenticated();
  const isVerification = userStore.isVerification();

  if (to.meta.needAuth) {
    if (!isAuthenticated) {
      return next({name: RoutesEnums.authorization});
    }
  }

  if (isAuthenticated) {
    if (to.meta.noAuth) {
      return next({name: RoutesEnums.personal});
    }
  }

  if (to.meta.needVerification) {
    if (!isVerification) {
      return next({name: RoutesEnums.verification});
    }
  }

  if (to.meta.noVerification) {
    if (isVerification) {
      return next({name: RoutesEnums.personal});
    }
  }

  if (to.meta.noVerification) {
    if (isVerification) {
      return next({ name: RoutesEnums.personal });
    }
  }

  return next();
});

export default router
